import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DotLoader } from "react-spinners";
import Axios from "./../../Utils/AxiosConfig";
import ApiNames from "../../Constants/ApiNames";

const Blogdata = () => {
  const location = useLocation();
  const [blogData, setblogData] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const { slug } = useParams();

  // Use a loading state or fetch data if necessary
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setblogData(location.state?.blogData);
    if (!location.state?.blogData) {
      setLoading(true);
      const url = ApiNames.Cerohero_Blogdata + slug;
      Axios.get(url)
        .then((res) => {
          // console.log(res.data[0]);
          setLoading(false);
          setblogData(res.data.post);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
    }
  }, [slug, location.state]);

  return (
    <div>
      <Helmet>
        <title>{blogData ? blogData?.title : "Blog Data"}</title>
        <meta name="description" content={blogData ? blogData?.description : "Loading blog data..."} />
        <meta property="og:title" content={blogData?.title} />
        <meta property="og:description" content={blogData?.description} />
        <meta property="og:image" content={blogData?.image} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blogData?.title} />
        <meta name="twitter:description" content={blogData?.description} />
        <meta name="twitter:image" content={blogData?.image} />
      </Helmet>

      <div className="blog-sub-page">
        <div className="container">
          <div className="blog-dBelow">
            {loading ? (
              <DotLoader color="#025F5C" loading={loading} size={100} />
            ) : (
              blogData && (
                <div className="row justify-content-center">
                  <div className="col-md-9 ps-lg-3">
                    <div className="B-right-Data">
                      <div className="B-cardsBelow">
                        <div className="B-card-bdy">
                          <h2 className="B-Cbody-tiitle">{blogData?.title}</h2>
                          {/* Author and Date */}
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <p className="text-muted mb-0">
                              <strong>Author:</strong> {blogData?.author ? blogData?.author : "Cerohero"}
                            </p>
                            <p className="text-muted mb-0">
                              <strong>Published:</strong> {new Date(blogData?.date).toLocaleString()}
                            </p>
                          </div>
                          <div className="B-cardImgs">
                            <img src={blogData?.image} alt="card" className="B-edit-CardIMG" />
                          </div>

                          {/* Blog Content */}
                          <div className="mt-3" dangerouslySetInnerHTML={{ __html: blogData?.description }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogdata;
